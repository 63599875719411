import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import LandingPage from './pages/LandingPage';
import BlogList from './pages/blog/BlogList';
import Article from './pages/blog/Article';
import Art from './pages/Art';
import About from './pages/About';
import FeelThePixels from './pages/blog/FeelThePixels';
import BackPropTheOverlord from './pages/blog/BackPropTheOverlord';

function App() {
	return (
		<ChakraProvider theme={theme}>
			<Routes>
				<Route path="/" element={<Layout />}>
					<Route index element={<LandingPage />} />
					<Route path="blog" element={<BlogList />} />
					<Route
						path="blog/feel-the-pixels"
						element={<FeelThePixels />}
					/>
					<Route
						path="blog/backpropogation-the-search-for-new-god"
						element={<BackPropTheOverlord />}
					/>
					<Route path="blog/:slug" element={<Article />} />
					<Route path="art" element={<Art />} />
					<Route path="about" element={<About />} />
				</Route>
			</Routes>
		</ChakraProvider>
	);
}

export default App;
