import {
	Box,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	VStack,
	Image,
	Text,
} from '@chakra-ui/react';
import React from 'react';

const Art: React.FC = () => {
	return (
		<Box p={5}>
			<Tabs variant="soft-rounded" colorScheme="green">
				<TabList>
					<Tab>Manual Art</Tab>
					<Tab>AI Art</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>
						<VStack spacing={5}>
							<Image
								boxSize="200px"
								src="https://pub-17b7496e137e40fcbe7057d6a4735482.r2.dev/manual-art:1.png"
								alt="1"
							/>
							<Image
								boxSize="200px"
								src="https://pub-17b7496e137e40fcbe7057d6a4735482.r2.dev/manual-art:2.png"
								alt="2"
							/>
							<Image
								boxSize="200px"
								src="https://pub-17b7496e137e40fcbe7057d6a4735482.r2.dev/manual-art:3.png"
								alt="3"
							/>
							<Image
								boxSize="200px"
								src="https://pub-17b7496e137e40fcbe7057d6a4735482.r2.dev/manual-art:4.png"
								alt="4"
							/>
							<Image
								boxSize="200px"
								src="https://pub-17b7496e137e40fcbe7057d6a4735482.r2.dev/manual-art:5.png"
								alt="5"
							/>
							<Image
								boxSize="200px"
								src="https://pub-17b7496e137e40fcbe7057d6a4735482.r2.dev/manual-art:6.png"
								alt="6"
							/>
							<Image
								boxSize="200px"
								src="https://pub-17b7496e137e40fcbe7057d6a4735482.r2.dev/manual-art:7.png"
								alt="7"
							/>
							<Image
								boxSize="200px"
								src="https://pub-17b7496e137e40fcbe7057d6a4735482.r2.dev/manual-art:8.png"
								alt="8"
							/>
							<Image
								boxSize="200px"
								src="https://pub-17b7496e137e40fcbe7057d6a4735482.r2.dev/manual-art:9.png"
								alt="9"
							/>
							<Text fontSize="md">Uploading some more soon!</Text>
						</VStack>
					</TabPanel>
					<TabPanel>
						<VStack spacing={10}>
							<Text fontSize="md">Uploading soon!</Text>
						</VStack>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};

export default Art;
