import { Box, HStack, Heading, Text } from '@chakra-ui/react';
import React from 'react';

const About: React.FC = () => {
	return (
		<Box p={5}>
			<Text>
				As a kid, I wanted to be an aerospace engineer. As a teenager, I
				wanted to be an electrical engineer. As a sophomore in college,
				I wanted to be a computer engineer, specifically AI
				engineer/researcher.
			</Text>
			<Text>
				But, did I achieve any of these? Well, yes, I did. Graduated as
				an Electrical Engineer, worked for an aerospace company, and, at
				present, practising applied AI research & software. I also did a
				lot of professional fine arts (specifically, digital art) at a
				company, in college & afterwards. As an applied AI researcher,
				my day to day work involves training & fine-tuning a lot of
				state of the art Computer Vision models, Large Language
				Models(LLM) (started working years [&gt;5] before chatGPT was a
				household name) and building PoC powered by the AI models. I
				have <strong>five patents</strong> at different stages, with{' '}
				<a
					style={{ color: 'blue', textDecoration: 'underline' }}
					href="https://patents.google.com/patent/EP3961425A1"
				>
					one published
				</a>{' '}
				and four patents in filing stage
			</Text>
			<Text>
				Enough about my past & present. What about future? Well, I am
				still figuring out. But, I am sure that I will be doing
				something that I love.
			</Text>
			<Text>
				Got an interesting opportunity/project for me? Drop an email:{' '}
				<a
					href="mailto:manjumbmb97@gmail.com"
					style={{ color: 'blue', textDecoration: 'underline' }}
				>
					manjumbmb97@gmail.com
				</a>{' '}
				or call me:{' '}
				<a
					href="tel:+918792623389"
					style={{ color: 'blue', textDecoration: 'underline' }}
				>
					+91 8792623389
				</a>
			</Text>
			<HStack>
				<Text>
					<a
						href="https://www.linkedin.com/in/manjunath-maigur/"
						style={{
							color: 'blue',
							textDecoration: 'underline',
						}}
					>
						Linkedin
					</a>
				</Text>
				<Text>
					<a
						href="https://pub-17b7496e137e40fcbe7057d6a4735482.r2.dev/manjunath_resume.pdf"
						style={{ color: 'green', textDecoration: 'underline' }}
					>
						Resume
					</a>
				</Text>
			</HStack>
		</Box>
	);
};

export default About;
