import { extendTheme } from '@chakra-ui/react';

const colors = {
	brand: {
		red: {
			100: '#ffaca6',
			200: '#e74435',
		},
		cream: {
			100: '#fff8ed',
			200: '#fff3e0',
			300: '#ffebdc',
		},
		green: {
			100: '#3dad90',
			200: '#119975',
		},
		backgroud: '#fffff9',
		primary: '#fffff9',
	},
};

export const theme = extendTheme({ colors });
