import React from 'react';
import MathJax from 'react-mathjax';

interface MathJaxTexProps {
	text: string;
}

const MathJaxTex: React.FC<MathJaxTexProps> = (props: MathJaxTexProps) => {
	const mathRegex = /\$(.+?)\$/g;
	// Split the text into an array of text and TeX elements
	const parts = props.text
		.split(mathRegex)
		.filter((part) => part.trim() !== '');
	return (
		<MathJax.Provider>
			<>
				{parts.map((part, index) => {
					// if (part.startsWith('$') && part.endsWith('$')) {
					if (
						props.text.match(mathRegex) !== null &&
						props.text.match(mathRegex)?.includes('$' + part + '$')
					) {
						// Render the TeX part
						return (
							<MathJax.Node key={index} inline formula={part} />
						);
					} else {
						// Render the text part
						return <span key={index}>{part}</span>;
					}
				})}
			</>
		</MathJax.Provider>
	);
};

export default MathJaxTex;
