import {
	Box,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Heading,
	Link,
	Text,
	VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BlogList: React.FC = () => {
	const navigate = useNavigate();

	return (
		<Box p={5}>
			<VStack spacing={5}>
				<Card>
					<CardHeader>
						<Heading fontSize="sm">
							Backpropogation - The search for a new god
						</Heading>
					</CardHeader>
					<CardBody>
						<Text fontSize="xs">
							{' '}
							Math behind backpropogation - Understanding chain
							rule and vectorizing partial derivatives computation
						</Text>
					</CardBody>
					<CardFooter>
						<Link
							fontSize="xs"
							color={'blue.500'}
							onClick={() =>
								navigate(
									'/blog/backpropogation-the-search-for-new-god'
								)
							}
						>
							Read Article
						</Link>
					</CardFooter>
				</Card>
				<Card>
					<CardHeader>
						<Heading fontSize="sm">Feel the pixels</Heading>
					</CardHeader>
					<CardBody>
						<Text fontSize="xs">
							Why do people fail to debug or visualize computer
							vision algorithms? The answer is simple, they don't
							understand how an image is represented in the
							computer.
						</Text>
					</CardBody>
					<CardFooter>
						<Link
							fontSize="xs"
							color={'blue.500'}
							onClick={() => navigate('/blog/feel-the-pixels')}
						>
							Read Article
						</Link>
					</CardFooter>
				</Card>

				<Card>
					<CardHeader>
						<Heading fontSize="sm">
							How to develop right intuitions for computer vision
							(a mathematical approach)?
						</Heading>
					</CardHeader>
					<CardBody>
						<Text fontSize="xs">
							We all love to apply cool filters on our images and
							probably some of you might have written
							programs/softwares to apply filters. But, have you
							really understood the math behind it? Do you have
							the right intuitions to represent the image in right
							mathematical form? Well, in this article, I will try
							to explain the math behind the image representation
							and how to develop the right intuitions for computer
							vision.
						</Text>
					</CardBody>
					<CardFooter>
						<Text fontSize="xs">
							Still in draft. Publishing soon!!
						</Text>
					</CardFooter>
				</Card>
				<Card>
					<CardHeader>
						<Heading fontSize="sm">Episodic replay!</Heading>
					</CardHeader>
					<CardBody>
						<Text fontSize="xs">
							Biological brain inspired approach to eliminate
							catastrophic forgetting in neural networks & to
							develop lifelong learning systems. Does it really
							work?
						</Text>
					</CardBody>
					<CardFooter>
						<Text fontSize="xs">
							Still in draft. Publishing soon!!
						</Text>
					</CardFooter>
				</Card>
			</VStack>
		</Box>
	);
};

export default BlogList;
