import { ColorModeScript } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { store } from './store';
import App from './App';
import { theme } from './theme';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<StoreProvider store={store}>
		<ColorModeScript initialColorMode={theme.config.initialColorMode} />
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</StoreProvider>
);
