import React, { useContext } from 'react';
import {
	Avatar,
	AvatarBadge,
	Badge,
	Button,
	Icon,
	Popover,
	PopoverCloseButton,
	PopoverContent,
	PopoverFooter,
	PopoverHeader,
	PopoverTrigger,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import { Nav, Navbar } from 'react-bootstrap';
import ColorModeSwitcher from './ColorModeSwitcher';
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

const NavBar: React.FC = () => {
	const colorMode = useColorModeValue('light', 'dark');
	const navigate = useNavigate();

	return (
		<Navbar
			bg={colorMode}
			variant={colorMode}
			collapseOnSelect
			expand="lg"
			className="p-3"
			style={{ fontWeight: 'bold' }}
		>
			<Navbar.Brand href="/">
				Musings of a statistical pattern matcher
			</Navbar.Brand>
			<Navbar bg={colorMode} variant={colorMode}>
				<Nav>
					<Nav.Link href="/blog">Blog</Nav.Link>
					<Nav.Link href="/about">About</Nav.Link>
					<Nav.Link href="https://pub-17b7496e137e40fcbe7057d6a4735482.r2.dev/manjunath_resume.pdf">
						CV
					</Nav.Link>
					<Nav.Link href="/art">Art</Nav.Link>
					<ColorModeSwitcher justifySelf="flex-end" />
				</Nav>
			</Navbar>
		</Navbar>
	);
};

export default NavBar;
